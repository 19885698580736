import React from "react"
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';

import ExternalLink from "./externallink"

const Footer = ({ siteTitle }) => (
    <footer class="page-footer fluid-container">
    <div class="row">
      <div class="col-xs-12">
        <div class="bg-primary padding-20">
          <div class="mbottom-small border-bottom">
            <div class="row flexbox">
              <div class="col-xs-12 col-ms-6 col-md-3 pbottom-small">
                <h2 class="h4">Opleidingen</h2>
                <ul class="linklist linklist-inverted">
                  <li><ExternalLink to="https://www.ugent.be/nl/opleidingen/bacheloropleidingen">Bacheloropleidingen</ExternalLink></li>
                  <li><ExternalLink to="https://www.ugent.be/nl/opleidingen/masteropleidingen">Masteropleidingen</ExternalLink></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="container-share col-xs-12 col-sm-4">
              <ul class="list-inline list-unstyled social">
                <li><a href="http://www.facebook.com/ugent"><FaFacebookF className="fa"/><span class="sr-only">Facebook</span></a></li>
                <li><a href="http://www.twitter.com/ugent"><FaTwitter className="fa" /><span class="sr-only">Twitter</span></a></li>
                <li><a href="http://www.linkedin.com/company/ghent-university"><FaLinkedinIn className="fa" /><span class="sr-only">Linkedin</span></a></li>
                <li><a href="https://www.instagram.com/ugent/"><FaInstagram className="fa" /><span class="sr-only">Instagram</span></a></li>
              </ul>
            </div>
            <div class="col-xs-12 col-sm-8">
              <ul class="list-inline list-unstyled pull-right">
                <li>&copy; 2020 Universiteit Gent</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
