import React, { Component } from 'react';
import { Link } from 'gatsby';
import LogoUGent from "../images/logo-ugent.svg";

import ExternalLink from "../components/externallink";

class Navbar extends Component {
    constructor(props) {
        super(props);
        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
        };
    }
    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed,
        });
    }
    render() {
        const collapsed = this.state.collapsed;
        const classOne = collapsed ? 'navbar-collapse col-sm-10 collapse' : 'navbar-collapse col-sm-10 collapse in';
        const classTwo = collapsed ? 'navbar-toggle collapsed' : 'navbar-toggle';
        return (
            <nav class="navbar navbar-default">
                <div class="row">
                    <div class="navbar-header col-xs-12 col-sm-2">
                        <div class="page-logo">
                            <Link to="/elek">
                                <img src={LogoUGent} alt="Universiteit Gent"></img>
                            </Link>
                        </div>
                        <button onClick={this.toggleNavbar} className={`${classTwo}`} role="button">
                            <span class="">MENU</span>
                            <div class="block">
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                                <span class="icon-bar"></span>
                            </div>
                        </button>
                    </div>
                    <div id="navbar" className={`${classOne}`} role="navigation">
                        <div class="row menu">
                            <div class="col-xs-12">
                                <div class="bg-primary spacer">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <ul class="nav-tertiary nav navbar-nav navbar-right">
                                                <li><ExternalLink to="https://www.ugent.be/">Home UGent</ExternalLink></li>
                                                <li><ExternalLink to="https://ufora.ugent.be/">UFORA</ExternalLink></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row menu">
                            <div class="col-xs-12">
                                <ul class="nav-primary nav navbar-nav main-top-nav">
                                    <li><Link to="/elek" activeClassName="active"><span>Algemeen</span></Link></li>
                                    <li><Link to="/elek/opbouw" activeClassName="active"><span>Opbouw van de opleiding</span></Link></li>
                                    <li><Link to="/elek/infrastructuur" activeClassName="active"><span>Infrastructuur</span></Link></li>
                                    <li><Link to="/elek/getuigenissen" activeClassName="active"><span>Getuigenissen</span></Link></li>

                                    
                                    <li id="infolinks" class="infolinks dropdown">
                                        <a aria-expanded="false" aria-haspopup="true" role="button" data-toggle="dropdown" class="dropdown-toggle" href="#">
                                            <span>Meer</span>
                                            <i class="glyphicon glyphicon-menu-down"></i>
                                        </a>
                                        <ul class="dropdown-menu">
                                            <li class="">
                                                <a href="https://www.ugent.be/ea/nl/opleidingen">
                                                    <span>Opleidingen</span>
                                                </a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.ugent.be/ea/nl/onderzoek">
                                                    <span>Onderzoek</span>
                                                </a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.ugent.be/ea/nl/diensten">
                                                    <span>Diensten</span>
                                                </a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.ugent.be/ea/nl/faculteit">
                                                    <span>Over de faculteit</span>
                                                </a>
                                            </li>
                                            <li class="">
                                                <a href="https://www.ugent.be/ea/nl/contact">
                                                <span>Contact</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;