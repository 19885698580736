import React from "react"

import {FaExternalLinkAlt} from "react-icons/fa"

const ExternalLink = (props) => {
    return (
            <a href={props.to} target="_blank" rel="noopener noreferrer">{props.children}<FaExternalLinkAlt /></a>
    )
}

export default ExternalLink