import React from "react"
import "../css/screen.scss"

import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <>
    <div class="header">
      {children}
    </div>
    <Footer />
    </>
  )
}

export default Layout
