import React from "react"
import Navbar from "./navbar";

const Header = ({ title }) => (

  <div class="fluid-container">
    <div class="row" id="top">
      <header class="pageheader col-xs-12 ">
        <Navbar/>
        <div class="row">
          <div class="col-xs-12 col-sm-6 col-sm-offset-2 branding-container faculty-5">
            <h1 >
              {title}
            </h1>
        </div>
        </div>
      </header>

  </div>
  </div >

)

export default Header
